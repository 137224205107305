// Here you can add other styles

// App Scrollbar Styling
$Scroller-color: #3c81b6;

::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  background-color: $Scroller-color;
  outline: 1px solid $Scroller-color;
}

.breadcrumb {
  margin-top: 1rem;
}
.breadcrumb-item a {
  color: #444;
}
.table{
  --cui-table-bg:none !important
}
.smartTable1 {
  table {
    tr:last-child {
      background-color: white;
    }
    td {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
    }
  }

  thead {
    background-color: #a8adb6 !important;
  }
  thead th {
    color: white;
  }
  input {
    padding: 0.1rem 0.1rem !important;
    min-height: calc(0.8em + 0.5rem + 2px) !important;
  }
  thead th span {
    position: absolute;
    top: 3px;
    right: 0;
  }
  .details {
    text-transform: capitalize;
  }
  .details > div span {
    display: inline-block;
    width: 150px;
  }
}

.smartTable {
  table thead{
    tr:last-child {
      background-color: #e8e8e8;
    }
    td {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
    }
  }

  thead {
    background-color: #a8adb6 !important;
  }
  thead th {
    color: white;
  }
  input {
    padding: 0.1rem 0.1rem !important;
    min-height: calc(0.8em + 0.5rem + 2px) !important;
  }
  thead th span {
    // position: absolute;
    opacity: .8 !important;
    top: 3px;
    right: 0;
  }
  .details {
    text-transform: capitalize;
  }
  .details > div span {
    display: inline-block;
    width: 150px;
  }
}

.powerdByLogo {
  color: #801900;
}

.footer {
  small {
    margin-right: 80px;
    line-height: 16px;
  }
}

@media (max-width: 1366px) {
  th,
  td {
    font-size: 0.9rem;
  }
  tr td:last-child {
    .icon {
      width: 1.2rem !important;
    }
  }

  .nav-tabs li a {
    font-size: 0.9rem;
    cursor: pointer;
  }
}
